import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BreadCrumb from './components/Breadcrumb';
import NavBar from './components/NavBar';
import SideBar from './components/sidebar/Sidebar';
import HomePage from './pages/homepage/HomePage';
import { Provider } from 'react-redux';
import store from './store/store';
import MyCompanion from './pages/my-companion/MyCompanion';
import PracticeID from './pages/practice-id/PracticeID';
import LensProposalBuilder from './pages/lens-proposal-builder/LensProposalBuilder';
import LegalNotices from './pages/legal-notices/LegalNotices';
import NeedExplorer from './pages/need-explorer/NeedExplorer';
import Prescription from './pages/prescription/Prescription';
import Measures from './pages/measures/Measures';
import routes from './constants/routes.constant';
import LensThickness from './pages/lens-thickness/LensThickness';
import Summary from './pages/summary/Summary';
import PackageOffer from './pages/package-offer/PackageOffer';
import Education from './components/education/Education';
import Products from './components/products/Products';
import Demonstrations from './pages/demonstrations/Demonstrations';
import Delivery from './pages/delivery/Delivery';
import Emat from './pages/emat/Emat';
import LicenseActivation from './pages/license-activation/LicenseActivation';
import Discovery from './pages/discovery/Discovery';
import iTheme from './interface/theme.interface';
import LensConsultationV2 from './pages/lens-consultation-v2/LensConsultationV2';
import DownloadAssets from './components/DownloadAssets';
import ReactGA from 'react-ga';
import RouteChangeTracker from './components/RouteChangeTracker';
import Language from './components/Language';
import axios from 'axios';
import Loading from './components/Loading';
import Tutorial from './pages/tutorial-content/Tutorial';
import FirstLogin from './components/first-login/FirstLogin';
import IndexedDBProvider from './utils/context/indexedDBContext';
import useIndexedDB from './utils/hooks/useIndexedDB';
import iRootState from './store/interface/root-state.interface';
import useENV from './utils/hooks/useEnv';
import AdvancePriceSetting from './pages/advance-price-setting/AdvancePriceSetting'; 
import iUserStore from './store/interface/user-store.interface';
import Varilux from './pages/varilux-g9/Varilux';
import ExperienceType from './types/experience.type';


function App() {
  const [theme, setTheme] = useState<iTheme>();
  const [globalAlert, setGlobalAlert] = useState<{
    show: boolean,
    message?: string,
    action?: () => void
  }>({
    show: false
  });
  const [experience, setExperience] = useState<ExperienceType>(ExperienceType.STANDARD);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(():any => {
    store.subscribe(() => {
      const state = store.getState();
      const appStore = state.app;
      return appStore.isModalOpen
    })
  })
  const oldTheme = useRef<string>();
  const [loading, setLoading] = useState<{
    show: boolean,
    text?: string,
    textOnly?: boolean
  }>({show: false});

  const loadingPrevValue = useRef<boolean>(false);
  const [db, setDB] = useState<IDBDatabase | undefined>(undefined);
  const dbRef = useRef<IDBDatabase | undefined>(db);
  const { connect, createTables, get,getAll, insert, update } = useIndexedDB();
  const env = useENV();

  useEffect(() => {
    watchAppTheme();    
    axiosInterceptors();

    async function connectIndexDB() {
      const indexDB = await connect((db: any) => createTables(db));
      setDB(indexDB)
    }
    connectIndexDB();
  }, []);

  useEffect(() => {
    dbRef.current = db;
    initializeGoogleAnalytics();
    if(!db)return;

    const isIndexedDbDataFixed = localStorage.getItem('isIndexedDbDataFixed');
    if(isIndexedDbDataFixed)return;
    fixIndexedDbData();
    localStorage.setItem('isIndexedDbDataFixed', 'true');
  }, [db]);

  const fixIndexedDbData = () => { 
    // Note: old user data: createdDate = string data type
    // new: createdDate = Date data type
    // query createdDate using string is not posible.  
    getAll(db as IDBDatabase, 'userStore', (data: any) => {
      return (typeof data.createdDate) === 'string';
    }).then( (result) => {
      console.log(result);
      result.forEach( (user: iUserStore) => {
        const createdDate = removeDateTime(user.createdDate as string);
        update(db as IDBDatabase, 'userStore', {...user, createdDate});
      })
    });
  }

  useEffect(()=>{
    store.subscribe(() => {
      const state = store.getState();
      const appStore = state.app;
      setIsModalOpen(appStore.isModalOpen)
    }
  )},[isModalOpen])

  const initializeGoogleAnalytics = () => {
    const accountDetails = store.getState().app.accountDetails;
    const currencyCode = accountDetails?.currency?.code;
    // const gaCode = accountDetails?.ga_code;
    if(!currencyCode)return;

    //for custom GA code
    // ReactGA.initialize( (gaCode !== undefined || gaCode !== "" ? gaCode : env.GA) ||  env.GA);
    ReactGA.initialize(env.GA);
    ReactGA.pageview('/');
  }

  const axiosInterceptors = () => {
    let isBackground = false;
    axios.interceptors.request.use(
      (req) => {
        const token = store.getState().app.token;
        isBackground = req.headers['background'];
        delete req.headers['background'];
        const requiredToken = req.headers['Authorization'];
        if(requiredToken)req.headers['Authorization'] = `Bearer ${token}`;
        if(!isBackground)setLoading({show: true});        
        return req;
      },
      (err) => {
        if(!isBackground)setLoading({show: false});    
        return Promise.reject(err);
      }
    );
    axios.interceptors.response.use(
      (res) => {
         // Add configurations here
         if (res.status === 201 || res.status === 200) {
          if(!isBackground)setLoading({show: false});  
         }
         return res;
      },
      (err) => {
        if(!isBackground)setLoading({show: false});  
        return Promise.reject(err);
      }
   );
  }

  const removeDateTime = (date: string) => {
    return new Date(new Date(date).setHours(0,0,0,0))
  }

  let throttle: any;
  const saveToLocalStorage = (state: iRootState) => {
      clearTimeout(throttle);
      throttle = setTimeout(() => {
        try {
          const serialisedState = JSON.stringify(state);
          localStorage.setItem("persistantState", serialisedState);

          const user = state.user;
          const lensThickness = state.lensThickness
          const dbCopy = dbRef.current as IDBDatabase;
          if(!dbCopy || !user || !user.id)return;
          const createdDate = removeDateTime(user.createdDate as string);

          get(dbCopy, 'userStore', user?.id).then( (result) => {
            if(!result){              
              if(!user.createdDate)return;
              insert(dbCopy, 'userStore', {...user, createdDate, lensThickness});
            } else { 
              update(dbCopy, 'userStore', {...user, createdDate, lensThickness});
            }
          })

        } catch (e) {
          console.warn(e);
        }
      }, 250);
  }

  const watchAppTheme = () => {
    store.subscribe( () => {
      const state = store.getState();

      const appStore = state.app;
      const currentTheme = appStore.theme;           
      // Note: update theme only when theme state is changed
      if(!oldTheme.current || oldTheme.current !== currentTheme.id){
        setTheme(currentTheme);
        oldTheme.current = currentTheme.id;
      }

      if(appStore.loading.show === undefined)return;
      if(appStore.loading.show !== loadingPrevValue.current){
        setLoading(appStore.loading);
        loadingPrevValue.current = appStore.loading.show
      }

      saveToLocalStorage(state);
      setExperience(appStore.experience);
    });
    setTheme(store.getState().app.theme)
  }

  if(!db)return null;
  return (
    <div className={`App App--${experience}${isModalOpen ? ` App--fixed` : ''}`} style={{ 
      // <div className={`App${isModalOpen ? ` App--fixed` : ''}`} style={{ 
      "--app-theme-main": theme?.main,
      "--app-theme-dark": theme?.dark,
      "--app-bubble-main": theme?.bubbleMain,
      "--app-bubble-sub": theme?.bubbleSub,  
      "--app-bubble": theme?.bubbleSub,
    } as React.CSSProperties}>

      <Provider store={store} >
        <IndexedDBProvider db={db}>
          <Router>
              <RouteChangeTracker/>
              <DownloadAssets/>
              <NavBar/>
              <BreadCrumb/>
              <Education/>
              <Language/>
              <FirstLogin/>
              <Products/>
              <Tutorial/>

          <Switch> 
            <Route path={routes.variluxG9.path} exact component={Varilux}/>
            <Route path={routes.home.path} exact component={HomePage}/>
            <Route path={routes.myCompanion.path} exact component={MyCompanion}/>
            <Route path={routes.practiceID.path} exact component={PracticeID}/>
            <Route path={routes.legalNotices.path} exact component={LegalNotices}/>
            <Route path={routes.lensProposalBuilder.path} exact component={LensProposalBuilder}/>
            <Route path={routes.needExplorer.path} exact component={NeedExplorer}/>
            <Route path={routes.prescription.path} exact component={Prescription}/>
            <Route path={routes.measures.path} exact component={Measures}/>
            <Route path={routes.lensConsultation.path} exact component={LensConsultationV2}/>
            <Route path={routes.lensThickness.path} exact component={LensThickness}/>
            <Route path={routes.summary.path} exact component={Summary}/>
            <Route path={routes.packageOffer.path} exact component={PackageOffer}/>
            <Route path={routes.delivery.path} exact component={Delivery}/>
            <Route path={routes.demonstration.path} exact component={Demonstrations}/>
            <Route path={routes.demonstration2.path} exact component={Demonstrations}/>
            <Route path={routes.emat.path} exact component={Emat}/>            
            <Route path={routes.whatsNew.path} exact component={Emat}/>            
            <Route path={routes.licenseActivation.path} exact component={LicenseActivation}/>            
            <Route path={routes.discovery.path} exact component={Discovery}/>            
            
            <Route path={routes.advanceSetting.path} exact component={AdvancePriceSetting}/>            

          </Switch>

          <SideBar/>
        </Router>
        </IndexedDBProvider>
      </Provider>

      <Loading show={loading.show} text={loading.text} textOnly={loading.textOnly}/>
    </div>
  );
}

export default App;
