import React, { useEffect, useRef, useState } from 'react';
import HalfCircleProgress from '../../../components/ui/HalfCircleProgress';
import Icon from '../../../components/ui/Icon';
import useBEM from '../../../utils/hooks/useBEM';
import Slider from "react-input-slider";
import Button from '../../../components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import lensThicknessSlice, { getlensThickness } from '../../../store/slices/lens-thickness.slice';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import LensPairType from '../../../types/lens-pair.type';
import iSubProduct from '../../../interface/sub-product.inteface';
import LensFamilyType from '../../../types/lens-family.type';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import appSlice, { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import axios from 'axios';
import ENDPOINTS from '../../../constants/endpoints';
import iPrescriptionData from '../../../interface/prescription-data.interface';
import iLensThicknessCalculation from '../../../interface/lens-thickness-calculation';
import useLensThickness from '../useLensThickness';
import iProduct from '../../../interface/product.inteface';
import eyeGif from './../../../assets/gif/eye.gif';
import iLensRecommendationPair from '../../../interface/lens-recommendaiton-pair.interface';
import productsSlice from '../../../store/slices/products.slice';
import iProductBrand from '../../../interface/product-brand.interface';
import productDemoSlice from '../../../store/slices/products-demo.slice';
import routes from '../../../constants/routes.constant';
import useGoto from '../../../utils/hooks/useGoto';
import eyeSVG from './../../../assets/svg/eye.svg';
import Alert from '../../../components/ui/Alert';
import CircleArrow from '../../../components/ui/CircleArrow';
import ExperienceType from '../../../types/experience.type';
import SvgIcon from '../../../components/ui/svg-icon/SvgIcon';
import EPROM_MOCK_DATA from '../../../constants/eprom.constants';
import useEprom from '../../../utils/hooks/useEprom';

interface Props {

}

const LensThicknessControl: React.FC<Props> = () => {
    // VARIABLES
    const [B, E] = useBEM('lens-thickness-control');
    // const [slider, setSlider] = useState(0);
    const dispatch = useDispatch();
    const goto = useGoto();
    const { isLensThickness } = useSelector(getLensConsultation)
    const {
        getSubProductsByLabel,
        getRequestType,
        skipEPROMValidation
    } = useLensConsultation();
    const { prescription, measures } = useSelector(getUser);
    const { lensThicknessSettings, accountDetails, experience } = useSelector(getApp);
    const { activePair, lensRecommendation, sliderPage, temporaryByPassProduct } = useSelector(getLensConsultation);
    // const { activePairSun, lensRecommendationSun } = useSelector(getLensConsultationSun);
    const [subProducts, setSubProducts] = useState<iSubProduct[]>([]);
    const { t } = useTranslation(useSelector(getApp));
    const { filterSubProductsWithEnabledIndex } = useLensThickness();
    const { tempBaseLensThicknessCalculation, tempLensThicknessCalculation, error, sliderCount, sliderValue, tempLensThickness } = useSelector(getlensThickness);
    const online = navigator.onLine;
    const [recommendationToDemo, setRecommendationToDemo] = useState<{
        correct?: iProduct,
        protect?: iProduct,
        enhance?: iProduct,
    }>();
    const [onHoverEye, setOnHoverEye] = useState(false);
    const isSunExp = experience === ExperienceType.SUN
    const lensRecommendationByExperience =  lensRecommendation
    const activePairByExperience =  activePair

    const [recommendationToDemoBrands, setRecommendationToDemoBrands] = useState<{
        correct?: iProductBrand,
        protect?: iProductBrand,
        enhance?: iProductBrand,
    }>();
    const {
        getMeasuresHighestValues,
        getLeftAndRightPrescription
    } = useLensConsultation();
    const [isConfirmEPROM, setConfirmEPROM] = useState(false);
    const isInitialLoad = useRef<boolean>(true);
    const previousRequestBody = useRef<string>('');
    const {
        checkIfValidMassTint,
        checkIfValidTreatment,
        filterByAvailableIndex,
        getEpromApiEndpoint,
        isValidOnEPROM
    } = useEprom();

    useEffect(() => {
        if (!lensRecommendation) return;
        const pair: iLensRecommendationPair = lensRecommendation[activePair] as iLensRecommendationPair;
        if (!pair) return;
        const withDemo = (product?: iProduct) => {
        if (!product ||
                !product.demo ||
                product.demo.length < 1
            ) return undefined;
            return product;
        }

        const recommendation = {
            correct: withDemo(pair.correct?.product),
            protect: pair.protect && pair.protect.length > 0 ? withDemo(pair.protect[0].product) : undefined,
            enhance: withDemo(pair.enhance?.product),
        }

        const recommendationBrands = {
            correct: pair?.correct?.brand || undefined,
            protect: pair.protect && pair.protect.length > 0 ? pair.protect[0].brand : undefined,
            enhance: pair?.enhance?.brand || undefined
        }
        setRecommendationToDemoBrands(recommendationBrands);
        setRecommendationToDemo(recommendation);
    }, [lensRecommendation, activePair])

    useEffect(() => {
        if (sliderPage < 3) return;
        const initialSubProducts = getSubProducts(activePair);
        let finalSubProducts = filterSubProductsWithEnabledIndex(initialSubProducts, lensThicknessSettings);

        if (accountDetails?.shop.eprom && lensRecommendation) {
            const correct = lensRecommendation[activePair]?.correct?.subProduct?.code;
            finalSubProducts = filterByAvailableIndex(correct as string, finalSubProducts);
        }

        const isWithOnePointFive = finalSubProducts.filter(x => x.index === 1.5).length > 0;
        if (!isWithOnePointFive) {
            finalSubProducts = [{
                id: '',
                label: '',
                index: 0,
                price: 0
            }, ...finalSubProducts];
        }

        setSubProducts(finalSubProducts);
        setSliderValuePairLoad(finalSubProducts);
    }, [activePair, sliderPage])

    useEffect(() => {
        const familyBE = lensRecommendation ? lensRecommendation[activePair] : ''
        const correctSubProductId = familyBE ? familyBE?.correct?.subProduct?.code : ''
        const protect = lensRecommendation ? lensRecommendation[activePair]?.protect : ''
        const isItaly = accountDetails?.shop?.country === 'it'
        const isBelgium = accountDetails?.shop?.country === 'be'
        const productItalyBypass = ['xperio_tinted','xperio_mirrors']
        const productBelgiumBypass = ['eyezen_kids','stellest', 'kids_standard_design']
        
        if(isBelgium && correctSubProductId){
            if(productBelgiumBypass.includes(correctSubProductId))return
        }

        if (sliderPage < 3 || !lensRecommendation) return;
        const family = lensRecommendation[activePair];
        const base = tempBaseLensThicknessCalculation[activePair];
        const isWithRX = prescription.values.sphere.od ? true : false;

        // localStorage.setItem("selectedIndex", JSON.stringify(family?.correct.subProduct?.index))
        const product = family?.correct.product;
        const subProductCode = family?.correct.subProduct?.code;
        const skipEPROM = skipEPROMValidation(
            accountDetails?.shop.region as string,
            product?.id as string,
            subProductCode as string
        );

        const isValidateEPROM = (accountDetails?.shop.eprom as boolean) && !skipEPROM;

        if (!base) {
            const pair = activePair === LensPairType.PAIR1 ? 0 : 1;

            if (!lensRecommendation) return;
            const data = {
                index: family?.correct.subProduct?.index,
                product_code: handleProductCode(product?.id as string),
                sub_product_code: handleSubProductCode(subProductCode as string),
                prescription: getLeftAndRightPrescription(prescription.values),
                measures: getMeasuresHighestValues(measures, pair),
                request_type: getRequestType(isWithRX, isValidateEPROM)
            }
            const dataString = JSON.stringify(data);
            // Note: dont call API if data is the same with previous data
            if (dataString === previousRequestBody.current) return;
            previousRequestBody.current = dataString;




            axios.post(getEpromApiEndpoint(), { data }).then(x => {
                if (family && isValidateEPROM) {
                    const isBelgium = accountDetails?.shop.country === "be";
                    const productIdsToCheck = getProductIdsToCheck(family, isBelgium);
                    const validProducts = x.data.data.eprom_recommendation;
                    isValidOnEPROM(productIdsToCheck, validProducts, (result: boolean) => {
                        setConfirmEPROM(!result);
                        const epromBOValidation = validateEPROMIndexes(data.index as number);
                        dispatch(lensConsultationSlice.actions.setValidOnEPROM(!!(result && epromBOValidation)))
                    })
                }

                if (
                    x.data.statuscode !== 200 ||
                    !x.data.data.lens_thickness ||
                    Object.keys(!x.data.data.lens_thickness).length === 0
                ) {
                    return
                }

                const {
                    max_edge_thk,
                    weight,
                    center_thk,
                    index
                } = x.data.data.lens_thickness;
    
                dispatch(lensThicknessSlice.actions.saveBaseLensThickness({
                    pair: activePair,
                    data: {
                        index: index,
                        productCode: data.product_code as string,
                        edge: max_edge_thk,
                        weight: weight,
                        center: center_thk,
                        prescription: getLeftAndRightPrescription(prescription.values),
                        measures: data.measures
                    }
                }))
            })
            return;
        }
        const recommendedIndex = recommendedSubProduct().index;
        
        // Additional Business Rule: when recommendation index and slider index are the same, do not return any value
        if ((tempLensThickness?.pair1 === recommendedIndex) || (tempLensThickness?.pair2 === recommendedIndex)) {
            dispatch(lensThicknessSlice.actions.saveLensThicknessDifference({
                pair: activePair,
                data: undefined
            }));
            return;
        }
        
        // const currentDiff = tempLensThicknessCalculation[activePair];
        const currentDiff = tempBaseLensThicknessCalculation[activePair];

        // Note: Compute only lens thickness and EPROM
        // when new product is not equal to previous product
        // and new index is not equal to previous index
        // and apply only this conditions on first load of the component
        // changing prescription will always compute
        if (
            currentDiff && 
            currentDiff?.index === recommendedIndex
            // currentDiff?.productCode === base.productCode &&
            // isInitialLoad.current
        ) {
            // if ((currentDiff?.index === 1.5 || recommendedIndex === 1.5) && isInitialLoad.current) return
            if ((currentDiff?.index === 1.5) && isInitialLoad.current) return
            // if (isInitialLoad.current) return
            dispatch(lensThicknessSlice.actions.saveLensThicknessDifference({
                pair: activePair,
                data: undefined
            }));
            isInitialLoad.current = false;
            return;
        }

        const data = {
            index: recommendedIndex,
            product_code: handleProductCode(base.productCode),
            sub_product_code: handleSubProductCode(subProductCode as string),
            prescription: getLeftAndRightPrescription(prescription.values),
            measures: base.measures,
            request_type: getRequestType(isWithRX, isValidateEPROM)
        }
        const dataString = JSON.stringify(data);

        // Note: dont call API if data is the same with previous data
        if (dataString === previousRequestBody.current) return;
        previousRequestBody.current = dataString;

        axios.post(getEpromApiEndpoint(), { data }).then(x => {
            if (family && isValidateEPROM) {
                const isBelgium = accountDetails?.shop.country === "be";
                const productIdsToCheck = getProductIdsToCheck(family, isBelgium);
                const validProducts = x.data.data.eprom_recommendation;
                isValidOnEPROM(productIdsToCheck, validProducts, (result: boolean) => {
                    setConfirmEPROM(!result);
                    const epromBOValidation = validateEPROMIndexes(data.index as number);
                    dispatch(lensConsultationSlice.actions.setValidOnEPROM(!!(result && epromBOValidation)))
                })
            }

            if (
                x.data.statuscode !== 200 ||
                !x.data.data.lens_thickness ||
                Object.keys(x.data.data.lens_thickness).length === 0
            ) {
                dispatch(lensThicknessSlice.actions.saveLensThicknessDifference({
                    pair: activePair,
                    data: undefined
                }));
                return
            }

            const {
                max_edge_thk,
                weight,
                center_thk
            } = x.data.data.lens_thickness;

            const diffData = {
                weight: computeDifferencePercentage(base.weight, weight),
                edge: computeDifferencePercentage(base.edge, max_edge_thk),
                center: computeDifferencePercentage(base.center, center_thk),
            }
            dispatch(lensThicknessSlice.actions.saveLensThicknessDifference({
                pair: activePair,
                data: {
                    index: recommendedIndex,
                    productCode: base.productCode,
                    edge: diffData.edge,
                    weight: diffData.weight,
                    center: diffData.center
                }
            }))
        })
    }, [
        tempBaseLensThicknessCalculation,
        activePair,
        sliderPage,
        lensRecommendation,
    ])

    // useEffect(() => {
    //     dispatch(lensThicknessSlice.actions.setSliderValue(slider))
    //     dispatch(lensThicknessSlice.actions.setSliderCount(1))
    // }, [slider])

    useEffect(() => {
        if (!lensRecommendation || !lensRecommendation[activePair] || sliderPage < 3) return;
        setSliderValuePairLoad(subProducts);
    }, [lensRecommendation])

    useEffect(() => {
        return () => {
            isInitialLoad.current = true;
        }
    }, [])

    useEffect(() => {
        // setSlider(recommendedSubProduct() && isLensThickness ? recommendedSubProduct().id.charAt(0) : 0)
        dispatch(lensThicknessSlice.actions.setSliderValue(recommendedSubProduct() && isLensThickness ? recommendedSubProduct().id.charAt(0) : 0))
        dispatch(lensConsultationSlice.actions.setIsLensThickness(false))
    }, [])

    // METHODS

     // Rule: Substitute Varilux X Series product_codes and sub_product_codes when XR Series is selected
    // Only applicable for Varilux XR series
    const handleProductCode = (productCode: string) => {
        if (productCode === "varilux_xr_series") {
            return "varilux_x_series"
        } else {
            return productCode
        }
    }
    const handleSubProductCode = (subProductCode: string) => {
        switch (subProductCode) {
            case "varilux_xr_design":
                return "varilux_x_design";
            case "varilux_xr_design_short":
                return "varilux_x_design_short";
            case "varilux_xr_fit":
                return "varilux_x_f360";
            case "varilux_xr_track":
                return "varilux_x_track";
            case "varilux_xr_4d":
                return "varilux_x_4d";
            case "varilux_xr_pro":
                return "varilux_xclusive_4d";
            
            default:
                return subProductCode;
        }
    }


    const getProductIdsToCheck = (family: any, checkEnhance = false) => {
        const protectIdsToCheck = family?.protect ? family?.protect.map((p: any) => p.product?.id) : [];
        const enhanceIdsToCheck = (family?.enhance && checkEnhance) ? [family?.enhance.product?.id] : [];
        return [
            ...protectIdsToCheck,
            ...enhanceIdsToCheck
        ]
    }

    const setSliderValuePairLoad = (subp: iSubProduct[]) => {
        if (!lensRecommendation) return;
        let sliderIndex = 0;
        let actualIndex;
        subp.forEach((x, i) => {
            if (lensRecommendation[activePair]?.correct.subProduct?.index === x.index) {
                sliderIndex = i;
                actualIndex = x.index;
            }
        });
        if (actualIndex && !validateEPROMIndexes(actualIndex)) {
            dispatch(appSlice.actions.showGlobalAlert({
                show: true,
                message: t('eprom_popup'),
            }));
            return;
        }
        
        // setSlider(sliderIndex);
        dispatch(lensThicknessSlice.actions.setSliderValue(sliderIndex))
    }

    const recommendedSubProduct = (): iSubProduct | any => {
        if (!lensRecommendation || !lensRecommendation[activePair]) return null;
        return lensRecommendation[activePair]?.correct?.subProduct as iSubProduct;
    }

    const getSubProducts = (pair: LensPairType): iSubProduct[] => {
        if (!lensRecommendation) return [];

        const correct = lensRecommendation[pair]?.correct;
        const label = correct?.subProduct?.label || '';
        if (!correct?.product) return [];

        return getSubProductsByLabel(
            label,
            correct.product.subProducts as iSubProduct[],
            true
        );
    }

    const viewPrescription = () => {
        dispatch(lensThicknessSlice.actions.changeView('prescription'));
    }

    let timerThrottle: any = null;
    const setManualThickness = (value: number) => {
        dispatch(lensThicknessSlice.actions.changeError('lensThickness'))
        if (subProducts.length <= 1) return;
        if (value === 0 && subProducts[value].index != 1.5) {
            return;
        }

        if (!validateEPROMIndexes(subProducts[value].index)) {
            dispatch(appSlice.actions.showGlobalAlert({
                show: true,
                message: t('eprom_popup'),
            }));
            return;
        }

        if (timerThrottle) clearTimeout(timerThrottle);
        timerThrottle = setTimeout(() => {
            setFinalSubProduct(subProducts[value])
        }, 300);
        // setSlider(value);
        dispatch(lensThicknessSlice.actions.setSliderValue(value))
    }

    const validateEPROMIndexes = (index: number) => {
        if (!lensRecommendation || !accountDetails?.shop.eprom) return true;
        const family = lensRecommendation[activePair];
        const correctCode = family?.correct.subProduct?.code as string;
        let protectIds = (family?.protect || []).map(x => x.product?.id) || [];
        //belgium
        if (accountDetails?.shop?.country === 'be') {
            protectIds = protectIds.filter(id => id !== 'xperio_mirrors')
        }
        //italy
        const italyProductsByPass = ['xperio_mirrors', 'xperio_tinted']
        if(accountDetails?.shop?.country === 'it' && protectIds[0] && protectIds.length === 1){
            if(italyProductsByPass.includes(protectIds[0])){
                protectIds = protectIds.filter(id => id !== 'xperio_mirrors')
                protectIds = protectIds.filter(id => id !== 'xperio_tinted')
            }
        }
        if(accountDetails?.shop?.country === 'it' && protectIds[0] && protectIds[1]){
            if(italyProductsByPass.includes(protectIds[0]) || italyProductsByPass.includes(protectIds[1])){
                protectIds = protectIds.filter(id => id !== 'xperio_mirrors')
                protectIds = protectIds.filter(id => id !== 'xperio_tinted')
            }
        }
        const enhanceId = family?.enhance?.product?.id;
        const masstingResult = protectIds.length ? checkIfValidMassTint(
            correctCode,
            protectIds as string[],
            index
        ) : true;

        const treatmentResult = enhanceId ? checkIfValidTreatment(
            correctCode,
            enhanceId as string,
            index
        ) : true;

        return masstingResult && treatmentResult;
    }

    const setFinalSubProduct = (subProduct: iSubProduct) => {
        if (!lensRecommendation) return;
        const correct = lensRecommendation[activePair]?.correct;
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.CORRECT,
            brand: correct?.brand,
            product: correct?.product,
            subProduct,
            manual: true,
            pair: activePair
        }));
    }

    const computeDifferencePercentage = (currentValue: number, newValue: number) => {
        let values = [currentValue, newValue];
        // if (newValue > currentValue) values = values.reverse();
        const diff = values[0] - values[1];
        const deffPercentage = (diff / values[0]) * 100;
        const finalPercent = newValue >= currentValue ? deffPercentage : (deffPercentage * -1);
        return Math.round(finalPercent);// Number(finalPercent.toFixed(2));
    }


    const updateThickness = (action: string) => {
        // Note: cannot update slider when you only 1 index
        if (subProducts.length <= 1) return;
        if (sliderValue === 0 && action === 'minus') return;
        if (sliderValue === subProducts.length - 1 && action === 'add') return      

        const newIndex = action === 'add' ? ((sliderValue as number) + 1) : ((sliderValue as number) - 1);
        dispatch(lensThicknessSlice.actions.changeError('lensThickness'))
        setManualThickness(newIndex);
    }

    const isPrescriptionComplete = () => {
        return (prescription.values && prescription.values.sphere.od) ? true : false
    }

    const disableDemo = () => {
        if (!recommendationToDemo) return true;
        const {
            correct,
            protect,
            enhance
        } = recommendationToDemo;
        if (!correct && !protect && !enhance) return true;
        return false;
    }

    const eyeIconGif = () => {
        return <div className={E('eye-blink')}
            onMouseEnter={() => onHoverHandler(true)}
            onMouseLeave={() => onHoverHandler(false)}
        >
            <img src={eyeGif} alt="" />
            <img className={onHoverEye ? 'gif' : 'svg'} src={onHoverEye ? eyeGif : eyeSVG} alt="" />
        </div>
    }

    const onHoverHandler = (isHover: boolean) => {
        setTimeout(() => {
            setOnHoverEye(isHover);
        }, isHover ? 0 : 500);
    }

    const demoProducts = () => {
        const initialDemo: any = getInitialDemo();
        if (!initialDemo) return;
        dispatch(productsSlice.actions.selectBrandTodemo(initialDemo.data.brand))
        dispatch(productsSlice.actions.selectProductTodemo(initialDemo.data.product))

        if (!recommendationToDemo || !recommendationToDemoBrands) return;

        dispatch(productDemoSlice.actions.setRecommendationDemo(recommendationToDemo));
        dispatch(productDemoSlice.actions.selectLensFamily(initialDemo.family));
        dispatch(productDemoSlice.actions.setDemoFrom(routes.lensConsultation));
        dispatch(productDemoSlice.actions.setRecommendationDemoBrands(recommendationToDemoBrands));

        goto.route(routes.demonstration);
    }

    const getInitialDemo = () => {
        if (!lensRecommendation) return;
        const pair: iLensRecommendationPair = lensRecommendation[activePair] as iLensRecommendationPair;
        const { correct, protect, enhance } = pair;
        if (isWithDemo(correct)) return { family: LensFamilyType.CORRECT, data: correct };
        if (isWithDemo(protect)) return { family: LensFamilyType.PROTECT, data: protect[0] };
        if (isWithDemo(enhance)) return { family: LensFamilyType.ENHANCE, data: enhance };
    }

    const isWithDemo = (family: any) => {
        if (Array.isArray(family)) {
            if (family && family.length > 0 && family[0].product && (family[0].product.demo || []).length > 0) return true;
            return false;
        }
        if (family && family.product && (family.product.demo || []).length > 0) return true;
        return false;
    }

    const confirmEPROM = (id: string) => {
        setConfirmEPROM(false);
        if (id === 'rx') return;
        sessionStorage.setItem('GLOBAL_EVENT', 'CHANGE_PROPOSAL')
        dispatch(lensConsultationSlice.actions.setSliderPage(0));
    }

    const dynamicErrorMessage = () =>{
        if(error === 'lensThickness')return 'Change Lens Thickness'
        else return  t('eprom_popup_change_rx')
    }

    const byPassXR = () => {
        let isVariluxXRRecommended = (lensRecommendation?.pair1?.correct?.product?.id) === 'varilux_xr_series'
        let isXRSubProductSelected = (lensRecommendation?.pair1?.correct?.subProduct) === undefined ? false : true
        if (lensRecommendation?.pair1?.correct?.subProduct === undefined) return false
        const xrProductsCode = [
            'varilux_xr_design',
            'varilux_xr_design_short',
            'varilux_xr_fit',
            'varilux_xr_track',
            'varilux_xr_4d',
            'varilux_xr_pro'
        ]
        return xrProductsCode.includes(temporaryByPassProduct as string) || isVariluxXRRecommended
    }

    return (
        <div className={B()}>
            <div className={E('controls')}>

                <div className={E('controls-head')}>

                    <label>{t('lens_thickness_lt_lens_thickness')}</label>

                    <Button
                        // experience={experience}
                        text={t('lens_thickness_lt_view_more_details')}
                        icon={
                            <CircleArrow
                                type='right'
                                background='#eee'
                            />
                        }
                        soon
                    />
                </div>
                <div className={E('controls-container')}>
                    <div className={E('controls-slider')}>

                        <SvgIcon
                            name='minus'
                            height='1.25rem'
                            circle
                            borderColor='#ddd'
                            onClick={() => updateThickness('minus')}
                        />
                        <Slider
                            styles={{
                                active: {
                                    // backgroundColor: experience === ExperienceType.SUN ? "#f35d1d" : "#0386D6",
                                    backgroundColor: "#0386D6",
                                },
                            }}
                            axis="x"
                            xstep={1}
                            xmin={0}
                            xmax={subProducts.length - 1}
                            x={sliderValue}
                            onChange={({ x }) => setManualThickness(x)}
                        />
                        <SvgIcon
                            name='add'
                            height='1.25rem'
                            circle
                            borderColor='#ddd'
                            onClick={() => updateThickness('add')}
                        />

                        <label className={E('controls-slider-index')}>
                            {new Array(subProducts.length).fill('').map((item, index) => (
                                <span key={index}></span>
                            ))}
                        </label>
                    </div>
                    <div className={E('lens-index')}>
                        {recommendedSubProduct() && recommendedSubProduct().index}
                    </div>
                </div>
            </div>

            <div className={E('notes')}>
                <label>
                    {
                        online ? '' :
                            `${t('lens_thickness_tl_warning_page_offline')} ${t('lens_thickness_tl_warning_page_note')}`
                    }
                </label>
                <label>
                    {t('lens_thickness_lt_*compared_to_the_reference_index')}
                </label>
            </div>

            {
                !byPassXR() && (
                    <Alert
                        show={isConfirmEPROM}
                        message={t('eprom_popup')}
                        buttons={[
                            { label: t('eprom_popup_change_proposal'), id: 'proposal' },
                            { label: dynamicErrorMessage(), id: 'rx' }
                        ]}
                        onButtonSelect={(id) => confirmEPROM(id)}
                    />
                )
            }
        </div>
    )
}

export default LensThicknessControl;
